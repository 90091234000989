<template>
   <ObiText class="obi-dcm" v-bind="attrs" v-on="$listeners">
      <ObiText class="obi-dcm-content">
         <ObiText class="obi-dcm-name" v-html="location.getName()" @click="changeTeam(location)" />
         <ObiText class="obi-dcm-location" v-html="location.getLocationFormatted()" />
         <ObiText
            v-if="showDate"
            class="text-success h3"
            :class="{
               'text-danger': location.get('license_remain') <= 0,
               'text-success': location.get('license_remain') > 0,
            }"
         >
            <span v-if="!location.get('is_trial') && location.get('license_remain') <= 0">
               Lisans Bitti: {{ trFormatDate(location.getLicenseEndAt()) }}</span
            >
            <span v-if="location.get('is_trial') && location.get('trial_remain') > 0">
               Demo Bitmesine Kalan {{ location.get('trial_remain') }} Gün</span
            >
            <span v-if="location.get('trial_remain') <= 0 && location.get('is_trial')"> Demo Bitti</span>
            <span v-if="!location.get('is_trial') && location.get('license_remain') > 0">
               Lisans Bitiş Tarihi: {{ trFormatDate(location.getLicenseEndAt()) }}</span
            >
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

import Location from '@/api/Models/Location';
import Notify from '@/libs/notify';
import PanelLocationApi from '@/api/PanelLocationApi';
import ErrorHandler from '@/libs/error-handler';
import { trFormatDate } from '@/libs/date-utils';
import AuthApi from '@/api/AuthApi';

export default {
   name: 'ObiLocationCard',

   i18n: {
      messages: {
         tr: {
            last_seen: 'Son Görülme',
            device_never_connected: 'Cihaz Hiç Bağlanmadı',
         },
         en: {
            last_seen: 'Last Seen',
            device_never_connected: 'Never Connected',
         },
      },
   },
   props: {
      location: {
         type: Location,
         default: null,
      },
      showDate: {
         type: Boolean,
         default: true,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
   data() {
      return {};
   },
   methods: {
      async applyLicense(year) {
         this.isLoading = true;
         try {
            await PanelLocationApi.applyLicense(this.$props.location.getId(), { year: year });
            Notify.success(this.$t('Lokasyon lisanslandı'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async changeTeam(team) {
         this.isLoadingLocations = true;
         try {
            await AuthApi.changeTeam(team.getId());
         } catch (error) {
            ErrorHandler.handle(error);
         } finally {
            this.isLoadingLocations = false;
         }
      },
      trFormatDate,
   },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-dcm {
   padding: 20px;
   display: grid;
   grid-gap: 20px;
   border-radius: 8px;
   align-items: center;
   background-color: #f3f8fe;
   justify-content: space-between;
   grid-template-columns: minmax(120px, max-content) 1fr auto;

   .obi-dcm-image {
      text-align: center;

      .obi-image {
         width: unset;
         max-width: 90px;
         max-height: 45px;
         object-fit: cover;
         object-position: center;
      }
   }

   .obi-dcm-content {
      display: grid;
   }

   .obi-dcm-name {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-dcm-location {
      font-size: 0.8rem;
   }

   .obi-dcm-status-icon {
      width: 12px;
      height: 12px;
      border-radius: 12px;

      &.online {
         background: #31ba3f;
      }

      &.offline {
         background: #ff8e8e;
      }
   }

   .obi-dcm-action {
   }
}
</style>
